<template>
  <div>
    <v-app>
      <v-main :class="($vuetify.theme.dark) ? 'grey darken-4' : 'primary lighten-3'">
       <v-container class="pb-6 pt-0 px-0" >
          
         
          <v-row class="ma-0 pa-0">
          


             <v-col cols="12" sm="10"  class="pa-0 mx-auto">
              <v-sheet min-height="90vh" color="transparent" class="rounded-b-xl"  elevation="6">
             
    

      <search-list-region :region="this.$route.params.region" />
      

    <v-btn block plain class="mx-0" @click="$router.go(-1)"> <v-icon left>
        mdi-arrow-left
      </v-icon>Back</v-btn>

              </v-sheet>
            </v-col>
            
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

export default {

  data() {
    return { };
  },
  
  

};
</script>
